const NextI18Next = require('next-i18next').default;
const path = require('path');

const localePath = path.resolve('./public/static/locales');
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['fr'],
  shallowRender: true,
  localeSubpaths,
  localePath,
});
