import Rollbar, { Configuration } from 'rollbar';

const ACCESS_TOKEN = process.env.ROLLBAR_SERVER_ACCESS_TOKEN || process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_ACCESS_TOKEN;
const BUILD_ENV = process.env.NEXT_PUBLIC_BUILD_ENV;
const BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID;

const rollbarConfig: Configuration = {
  accessToken: ACCESS_TOKEN,
  enabled: Boolean(ACCESS_TOKEN),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: BUILD_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: BUILD_ID,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const rollbar = new Rollbar(rollbarConfig);
