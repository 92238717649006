import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { rollbar } from './instance';

interface IProps {
  children: React.ReactNode;
}

const RollbarProvider: React.FC<IProps> = (props) => {
  const { children } = props;
  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
