import { alpha } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import mixins from './mixins';

const overrides: any = {
  MuiCssBaseline: {
    styleOverrides: {
      root: {
        '@global': {
          html: {
            scrollBehavior: 'smooth',
          },
          b: {
            fontWeight: typography.fontWeightMediumBold,
          },
          '#fb-root': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h4: {
        '@media (max-width: 1023px)': {
          ...typography.h5,
        },
      },
      h5: {
        '@media (max-width: 1023px)': {
          ...typography.h6,
        },
      },
      body2: {
        '& b': {
          fontWeight: 500,
        },
      },
      button: {
        'a&': {
          fontSize: 13,
          lineHeight: '19px',
        },
      },
      colorPrimary: {
        color: palette.primary.main,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        elevation: 0,
      },
      colorPrimary: {
        backgroundColor: palette.background.default,
        color: palette.primary.main,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: palette.primary.light,
        borderRadius: 15,
        fontSize: 13,
        border: 'none',
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: '#FFFFFF',
        },
        '&$selected': {
          border: `1px solid ${palette.primary.main}`,
          backgroundColor: 'unset',
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.main,
            color: '#FFFFFF',
          },
        },
      },
      sizeSmall: {
        height: 30,
        padding: '0px 16px',
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(78, 109, 245, 0.07)',
        borderRadius: 19,
        width: '100%',
      },
      grouped: {
        width: '100%',
        '&:not(:first-child)': {
          borderLeft: '',
          marginLeft: 8,
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
        },
        '&:not(:last-child)': {
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
        },
      },
      groupedSizeSmall: {
        padding: '0px 15px 0px 16px',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:focus-visible': {
          backgroundColor: 'unset',
        },
        '&button:hover': {
          backgroundColor: alpha(palette.primary.main, 0.07),
          color: palette.primary.main,
        },
        '&.Mui-selected': {
          backgroundColor: alpha(palette.custom.gray.dark, 0.07),
          '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.07),
            color: palette.primary.main,
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, 0.07),
          color: palette.primary.main,
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'rgba(78, 109, 245, 0.07)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(106, 120, 152, 0.07)',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        minHeight: 40,
        '&:hover': {
          borderRadius: 8,
          backgroundColor: '#F5F6FA',
        },
        '@media (max-width: 600px)': {
          minHeight: 40,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: '8px 8px',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      regular: {
        minHeight: 64,
      },
      dense: {
        minHeight: 56, // mixins.appBar[breakpoints.down('sm')] how to handle
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      disableElevation: true,
      root: {
        padding: '13px 24px',
        borderRadius: 6,
        overflow: 'hidden',
        transitionProperty: 'background-color, box-shadow',
        boxShadow: 'unset',
      },
      startIcon: {
        '&$iconSizeSmall': {
          marginLeft: 0,
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: 16,
        },
      },
      sizeSmall: {
        lineHeight: '19px',
      },
      sizeLarge: {
        borderRadius: 8,
        fontSize: 16,
        lineHeight: '24px',
      },
      outlined: {
        border: `2px solid white`,
        padding: '11px 22px',
        '&$disabled': {
          borderWidth: 2,
        },
      },
      text: {
        padding: '13px 24px',
      },
      outlinedPrimary: {
        border: `2px solid ${palette.primary.main}`,
        '&:hover': {
          border: `2px solid ${palette.primary.main}`,
        },
      },
      outlinedSecondary: {
        border: `2px solid ${palette.secondary.main}`,
        '&:hover': {
          border: `2px solid ${palette.secondary.main}`,
        },
      },
      containedPrimary: {
        borderRadius: 8,
        '&$disabled, &:hover$disabled': {
          backgroundColor: 'rgba(123, 139, 173, 0.4)',
          color: '#FFF',
        },
      },
      containedSecondary: {
        color: '#FFF',
      },
      outlinedSizeSmall: {
        padding: '5px 14px 4px',
      },
      outlinedSizeLarge: {
        padding: '10px 38px',
      },
      containedSizeSmall: {
        padding: '7px 16px 6px',
      },
      containedSizeLarge: {
        padding: '12px 40px',
      },
    },
  },
  MuiTouchRipple: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        overflow: 'hidden',
        '&:hover': {
          backgroundColor: palette.primary.extraLight,
        },
        '&.Mui-disabled': {
          opacity: 'unset',
          borderColor: 'white',
          color: `${palette.text.disabled} !important`,
          backgroundColor: palette.custom.gray.light,
        },
      },
      label: {
        paddingLeft: 16,
        paddingRight: 16,
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      colorSecondary: {
        border: `1px solid ${palette.secondary.main}`,
        color: '#FFF',
      },
      colorPrimary: {
        color: palette.primary.main,
        border: '1px solid #4E6DF5',
        '&:hover': {
          backgroundColor: palette.primary.main,
        },
      },
      colorDefault: {
        color: `${palette.primary.main} !important`,
        backgroundColor: palette.background.default,
      },
      filled: {
        color: '#FFF',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      button: {
        fontSize: 13,
        fontFamily: typography.fontFamily,
        lineHeight: '19px',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      rounded: {
        borderRadius: 8,
      },
      colorDefault: {
        color: 'inherit',
        backgroundColor: palette.background.default,
      },
      root: {
        ...typography.h6,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      light: {
        backgroundColor: palette.primary.extraLight,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        width: 44,
        height: 44,
        overflow: 'hidden',
        backgroundColor: '#FFF',
        boxShadow: '0px 4px 8px #233558',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        '&:hover fieldset': {
          borderWidth: 2,
          borderColor: `${palette.custom.gray.main} !important`,
        },
        '&.Mui-focused fieldset': {
          borderWidth: 2,
          borderColor: `${palette.custom.gray.main} !important`,
        },
        '&.Mui-error fieldset': {
          borderWidth: 1,
          borderColor: '#F65675 !important',
        },
        '&.Mui-disabled fieldset': {
          borderWidth: 1,
          borderColor: `unset !important`,
        },
      },
      input: {
        padding: '8px 16px',
        lineHeight: 1.6,
      },
      notchedOutline: {
        borderColor: palette.custom.border.gray,
      },
      multiline: {
        padding: '8px 16px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: '#383B44',
        top: -8,
        '&:not(.MuiInputLabel-shrink)': {
          color: '#7B8BAD',
        },
        '&.Mui-focused': {
          color: '#383C44',
        },
        '&.Mui-error': {
          color: '#383C44',
        },
      },
      shrink: {
        transform: 'translate(0px, -10px) scale(0.75) !important',
        color: '#383B44',
        letterSpacing: '0.03em',
        fontWeight: typography.fontWeightMediumBold,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '133%',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      autoComplete: 'off',
      input: {
        height: '24px',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      variant: 'outlined',
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
  },
  PrivateNotchedOutline: {
    styleOverrides: {
      legendNotched: {
        maxWidth: '0px',
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 2,
        borderRadius: 2,
      },
      colorPrimary: {
        backgroundColor: palette.custom.gray.light,
      },
      bar1Determinate: {
        opacity: 1,
        borderRadius: 2,
        backgroundColor: palette.primary.main,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginRight: 0,
        marginLeft: -10,
      },
      label: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.2,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: 16,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        elevation: 0,
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: 8,
      },
      elevation8: {
        boxShadow: '0px 4px 8px rgba(60, 85, 165, 0.16)',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        maxHeight: 40,
        color: palette.text.primary,
        ...typography.body1,
        padding: '8px 0px 8px 16px',
        '&.Mui-focused': {
          borderColor: '#7B8BAD',
        },
      },
      select: {
        ...typography.body1,
        padding: '4px 0px 4px 16px',
      },
      icon: {
        color: palette.text.secondary,
        marginRight: '5px',
      },
      outlined: {
        borderRadius: 8,
        '&$outlined': {
          paddingRight: 40,
          borderRadius: 8,
        },
      },
      notchedOutline: {
        borderColor: '#7B8BAD',
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      monthText: {
        color: palette.primary.main,
      },
      monthTitleContainer: {
        color: palette.primary.main,
        alignItems: 'center',
      },
      switchHeader: {
        paddingBottom: 12,
        borderBottom: `1px solid ${palette.custom.border.gray}`,
      },
      yearSelectionSwitcher: {
        color: palette.primary.main,
      },
    },
  },
  MuiPickersBasePicker: {
    styleOverrides: {
      pickerView: {
        maxHeight: 359,
        '@media (max-width: 600px)': {
          width: '100%',
        },
      },
      container: {
        padding: 24,
      },
    },
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      iconButton: {
        color: palette.primary.main,
        backgroundColor: 'none',
      },
    },
  },
  MuiPickersCalendarView: {
    styleOverrides: {
      viewTransitionContainer: {
        borderBottom: `1px solid ${palette.custom.border.gray}`,
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: 500,
        color: palette.text.primary,
        fontSize: 15,
        borderRadius: 6,
        '&:first-child:not($daySelected):not(:only-child)': {
          color: palette.error.main,
        },
      },
      daySelected: {
        backgroundColor: palette.primary.main,
        color: '#FFF !important',
      },
    },
  },
  MuiPickersDateRangeDay: {
    styleOverrides: {
      rangeIntervalDayHighlight: {
        backgroundColor: palette.custom.gray.light,
      },
      rangeIntervalDay: {
        '&:first-child button': {
          color: palette.error.main,
        },
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        flexWrap: 'nowrap',
        padding: '8px 16px',
        color: palette.text.primary,
        backgroundColor: palette.primary.light,
      },
      message: {
        padding: 0,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '0 32px 32px',
        '&:first-child': {
          paddingTop: '32px',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 13,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        '&$disabled': {
          color: 'rgba(60, 85, 165, 0.3)',
        },
      },
    },
  },
  MuiGridListTile: {
    styleOverrides: {
      root: {
        listStyle: 'none',
      },
    },
  },
  MuiGrid: {
    styleOverrides: {
      'direction-xs-column': {
        '& > $grid-xs-true': {
          maxWidth: 'initial',
        },
      },
    },
  },
  MuiStepButton: {
    styleOverrides: {
      root: {
        width: 'initial',
        boxSizing: 'border-box',
        padding: 8,
        margin: -8,
      },
    },
  },
  PrivateSwitchBase: {
    styleOverrides: {
      root: {
        padding: 8,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      body: {
        padding: '16px 16px 16px 0',
        '&:last-child': {
          padding: '16px 0',
        },
      },
      head: {
        padding: '0 16px 16px 0',
        color: palette.text.secondary,
        ...typography.body2,
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingTop: 6,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        ...mixins.disableScrollBar,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 18px',
        borderRadius: 8,
      },
      standardError: {
        backgroundColor: '#FEEBEE',
        color: palette.error.main,
      },
      standardInfo: {
        backgroundColor: '#edf0fe',
        color: palette.info.main,
        '& $icon': {
          color: palette.info.main,
        },
      },
      standardWarning: {
        color: palette.warning.main,
        backgroundColor: '#FBE9E7',
        '& $icon': {
          color: palette.warning.main,
        },
      },
      icon: {
        padding: '8px 0',
      },
    },
  },
} as const;

export default overrides;
